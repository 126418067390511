import { Input, TextArea } from 'common'
import { IQuoteZones, IZonesHandlers } from 'models'

interface ZonesFormProps {
	sections: IQuoteZones[]
	sectionsHandlers: IZonesHandlers
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: any
	// for errors use FormikErrors<IQuoteForm>
}

export const SectionsForm = ({
	sections,
	sectionsHandlers,
	errors,
}: ZonesFormProps) => {
	const sectionsArray = sections.filter(
		(item) => item.zone_label !== 'Additional'
	)
	return (
		<>
			<div className="grid grid-cols-2 items-start">
				{sectionsArray.map((section, index) => (
					<>
						<div className="flex items-center" key={index}>
							<Input
								id={`${index + 1}`}
								title={`Section ${index + 1}`}
								labelInline
								placeholder={`Section ${index + 1}`}
								handleBlur={() => null}
								type="text"
								value={section.zone_label}
								error={errors.zones?.[index]?.zone_label}
								handleChange={(e) =>
									sectionsHandlers?.updateZone(
										index,
										'zone_label',
										e.target.value
									)
								}
							/>
						</div>
						<div className="flex items-center" key={index}>
							<TextArea
								title="Description"
								handleChange={(e) =>
									sectionsHandlers?.updateZone(
										index,
										'description',
										e.target.value
									)}
								placeholder="Description"
								id={`${index + 1} Description`}
								value={section.description}
								error={errors.zones?.[index]?.description}
								rows={5}
							/>
						</div>
					</>
				))}
			</div>
		</>
	)
}

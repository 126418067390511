import React from 'react'
import { Input, Dropdown, DateSelect, Checkbox, CreateFile } from 'common'
import { IStaffRow } from 'models/staff.model'
interface HighRiskWorkLicenseProps {
	values: IStaffRow
	staff?: IStaffRow[]
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (
		field: string,
		value: unknown,
		shouldValidate?: boolean | undefined
	) => void
	setFieldTouched: (
		field: string,
		isTouched?: boolean | undefined,
		shouldValidate?: boolean | undefined
	) => void
}

// Class options:  SA, SI, SB, LF
const classOptions = [
	{ value: 'SA', label: 'SA' },
	{ value: 'SI', label: 'SI' },
	{ value: 'SB', label: 'SB' },
	{ value: 'LF', label: 'LF' },
]

export const HighRiskWorkLicense: React.FC<HighRiskWorkLicenseProps> = ({
	values,
	staff,
	handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
}) => {
	const renderStaffList = () => {
		if (staff && staff?.length > 0) {
			return staff.map((item) => ({
				value: item.staff_name || '',
				label: item.staff_name,
			}))
		}
		return []
	}
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5 mt-4">
				High Risk Work License
			</h3>
			<div className="flex items-center px-2">
				<div className="w-1/2">
					<Input
						title="License Number"
						id="cert_num"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.cert_num}
						placeholder="License Number"
					/>
				</div>
				<div className="w-1/2 mt-6">
					<DateSelect
						title="Expiry"
						id="cert_expiry_date"
						value={`${values.cert_expiry_date}`}
						onChange={setFieldValue}
					/>
				</div>
			</div>
			<div className="flex items-center mb-6">
				<Checkbox
					title="Options"
					options={classOptions}
					id="cert_options"
					values={JSON.parse(values?.cert_options || '[]')}
					onChange={(id: string, values: string[]) => {
						setFieldValue(id, JSON.stringify(values))
					}}
				/>
			</div>
			<div className="flex items-center">
				<div className="w-1/2 px-2">
					<h4 className="text-md font-normal leading-5 px-4">Photo - Front</h4>
					<CreateFile
						field="cert_photo"
						setFieldValue={setFieldValue}
						value={values.cert_photo}
					/>
				</div>
				<div className="w-1/2">
					<h4 className="text-md font-normal leading-5 px-4">Photo - Back</h4>
					<CreateFile
						field="cert_photo_back"
						setFieldValue={setFieldValue}
						value={values.cert_photo_back}
					/>
				</div>
				<div className="w-1/2 px-2">
					<Dropdown
						label="Assessed By"
						id="scaff_cert_assessed_by"
						value={values.scaff_cert_assessed_by}
						onChangeVal="scaff_cert_assessed_by"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={renderStaffList()}
					/>
				</div>
			</div>
		</div>
	)
}

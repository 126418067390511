import { Dropdown, Input, SideModal, Spinner, TextArea } from 'common'
import { useFormik } from 'formik'
import { InvoiceServices, QuoteServices } from 'services'
import { OptionsForDropdown } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	invoice_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IinitialValues {
	zone: string
	zone_label: string
	type: string
	description: string
	complete_percent: number
	total: number
	PO_Number: string
	amount_to_invoice: number
	task_id: string
}
export const EdInvoiceEditForm = ({
	invoice_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: invoiceData, isLoading: invoiceLoading } =
		InvoiceServices.useEdInvoiceById(invoice_id)

	const { data: rates, isLoading } = QuoteServices.useServiceRates()

	const { updateEdInvoice } = InvoiceServices.useUpdateEDInvoice()
	const { createEdInvoice } = InvoiceServices.useCreateEDInvoice()

	const initialValues: IinitialValues = {
		complete_percent: invoiceData?.complete_percent || 0,
		total: invoiceData?.total || 0,
		zone: invoiceData?.zone || '',
		zone_label: invoiceData?.zone_label || '',
		type: invoiceData?.type || '',
		description: invoiceData?.description || '',
		PO_Number: invoiceData?.PO_Number || '',
		amount_to_invoice: 0,
		task_id: invoiceData?.task_id || '',
	}

	const validationSchema = Yup.object({
		amount_to_invoice: Yup.number().test(
			'is-not-greater-than-or-equal',
			'Amount to invoice cannot be greater than or equal the total invoiceable',
			function (value) {
				const { total } = this.parent
				if (value != null && total != null) {
					return value < total
				}
				return true
			}
		),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'update' && invoice_id) {
				if (values.amount_to_invoice) {
					await createEdInvoice(invoiceData.job_id, {
						task_id: Number(values.task_id),
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						description: values.description,
						PO_Number: values.PO_Number,
						complete_percent: 0,
						total: values.amount_to_invoice,
					})
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						PO_Number: values.PO_Number,
						description: values.description,
						total: values.total - values.amount_to_invoice,
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						task_id: Number(values.task_id),
					})
				} else {
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						PO_Number: values.PO_Number,
						description: values.description,
						total: values.total,
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						task_id: Number(values.task_id),
					})
				}
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	const handleBlur = (event: any) => {
		let value = event.target.value
		const name = event.target.name

		if (!isNaN(value) && value !== '') {
			value = parseFloat(value).toFixed(2)
			formik.setFieldValue(name, value)
		}
	}

	if (invoice_id && (invoiceLoading || isLoading)) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone}
							error={formik.errors.zone}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Section Label"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section Label"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="type"
							label="Type"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							value={formik.values.type}
							error={formik.errors.type}
							options={OptionsForDropdown(rates, 'service', 'service')}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="PO Number"
							id="PO_Number"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="e.g. PO-1234"
							value={formik.values.PO_Number}
							error={formik.errors.PO_Number}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2 h-40">
					<div className="w-full ">
						<TextArea
							title="Description"
							id="description"
							type="text"
							className=" resize-none"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							rows={5}
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2 flex items-end">
						<Input
							id="amount_to_invoice"
							title="Amount to Invoice"
							placeholder="amount_to_invoice"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.amount_to_invoice}
							error={formik.errors.amount_to_invoice}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Total Invoiceable"
							id="total"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}

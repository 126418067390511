import { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { TwoColumnDetails, Section } from 'common/Details'
import { Tabs, Spinner, ErrorComponent } from 'common'
import { StaffServices } from 'services'
import { AppRoutes } from 'config'
import { Notes } from 'components/Notes'

export const DetailsPage = () => {
	const [tabIndex, setTabIndex] = useState(0)

	const { id } = useParams()
	const location = useLocation()

	const { data, error, isLoading, enableCreateUpdate } =
		StaffServices.useStaffById(id)

	const items = [
		{ label: 'Notes & Files', id: 0 },
		{ label: 'Leave', id: 1 },
	]

	if (isLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	if (error) {
		return <ErrorComponent />
	}

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			{data && (
				<TwoColumnDetails
					heading="Staff Details"
					editBtn="Edit Staff"
					isEditable={enableCreateUpdate}
					editLink={{
						to: AppRoutes.privateRoutes.StaffEdit.replace(':id', id || ''),
						state: { background: location, name: 'editStaff' },
					}}>
					<Section title="Staff Name" content={data.staff_name} />
					<Section title="Full Name" content={data.full_name} />
					<Section title="Date of Birth" content={data.dob} />
					<Section title="Start Date" content={data.start_date} />
					<Section title="Address" content={data.street} />
					<Section title="Contact #" content={data.mobile} />
					<Section title="Email" content={data.email} />
					<Section title="Status" content={data.status} />
					<Section title="" content="" />
					<Section title="" content={null} />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Working at Heights
					</h2>
					<div />
					<Section title="Issue Date" content={data.confined_date} />
					<Section title="Expiry Date" content={data.confined_expiry_date} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">File</h4>
						<img
							className="object-contain w-56"
							alt={data?.confined_file || ''}
							src={data?.confined_file || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Driver License
					</h2>
					<div />
					<Section title="License Number" content={data.driver_license} />
					<Section title="License Type" content={data.license_type} />
					<Section
						title="License Class"
						content={data.license_class2
							.replace('[', '')
							.replace(']', '')
							.replaceAll('"', '')
							.replace(',', ', ')}
					/>
					<Section title="Endorsement" content={data.endorsement} />
					<Section title="Expiry Date" content={data.endorsement_expiry} />
					<Section title="" content={null} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo Front</h4>
						{data?.photo_front && data?.photo_front.slice(data?.photo_front.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.photo_front}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.photo_front || ''}
								src={data?.photo_front || ''}
							/>
						)}
					</div>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo Back</h4>
						{data?.photo_back && data?.photo_back.slice(data?.photo_back.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.photo_back}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.photo_back || ''}
								src={data?.photo_back || ''}
							/>
						)}
					</div>

					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Health & Safety Employment Induction
					</h2>
					<div />
					<Section title="Induction Date" content={data.induction_date} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						{data?.photo && data?.photo.slice(data?.photo.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.photo}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.photo || ''}
								src={data?.photo || ''}
							/>
						)}
					</div>
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Construction Induction (White Card)
					</h2>
					<div />
					<Section title="Card Number" content={data.passport_num} />
					<Section title="RTO Number" content={data.passport_rto} />
					<Section title="Issue Date" content={data.passport_issue} />
					<div />
					<div>
						<h4 className="text-sm font-medium text-gray-500">
							Passport Photo
						</h4>
						{data?.passport_photo && data?.passport_photo.slice(data?.passport_photo.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.passport_photo}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.passport_photo || ''}
								src={data?.passport_photo || ''}
							/>
						)}
					</div>
					<Section title="" content={null} />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						First Aid Certificate
					</h2>
					<div />
					<Section title="Issue Date" content={data.first_aid_issue} />
					<Section title="Expiry Date" content={data.first_aid_expiry} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">
							First Aid Photo
						</h4>
						{data?.first_aid_photo && data?.first_aid_photo.slice(data?.first_aid_photo.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.first_aid_photo}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.first_aid_photo || ''}
								src={data?.first_aid_photo || ''}
							/>
						)}
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						High Risk Work License
					</h2>
					<div />
					<Section title="License Number" content={data.cert_num} />
					<Section
						title="License Options"
						content={data.cert_options
							.replace('[', '')
							.replace(']', '')
							.replaceAll('"', '')
							.replace(',', ', ')}
					/>
					{/* <Section title="Issue Date" content={data.cert_issue_date} /> */}
					<Section title="Expiry Date" content={data.cert_expiry_date} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						{data?.cert_photo && data?.cert_photo.slice(data?.cert_photo.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.cert_photo}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.cert_photo || ''}
								src={data?.cert_photo || ''}
							/>
						)}
					</div>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo Back</h4>
						{data?.cert_photo_back && data?.cert_photo_back.slice(data?.cert_photo_back.length - 4) ===
						'.pdf' ? (
							<Link className='text-blue-400 hover:underline' to={data?.cert_photo_back}>View Attached PDF</Link>
						) : (
							<img
								className="object-contain w-56"
								alt={data?.cert_photo_back || ''}
								src={data?.cert_photo_back || ''}
							/>
						)}
					</div>
					{/* <Section title="Kiwisaver" content={data.kiwisaver} /> */}
				</TwoColumnDetails>
			)}
			<div className="px-8">
				<Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
			</div>
			{tabIndex === 0 && <Notes type="staff" id={id || ''} />}

			{tabIndex === 1 && <div>Tab For Leave</div>}
		</div>
	)
}

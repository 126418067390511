import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { QuoteData, IQuoteLine, IQuoteAdditionalLines } from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import { Greetings } from './Greetings'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	const linesTotal = quote_lines
		.map((line) => Number(line.erect_and_dismantle))
		.reduce((acc, curr) => acc + curr, 0)
	const linesHireTotal = quote_lines
		.map((line) => Number(line.weekly_hire_fee) * Number(line.hire_period))
		.reduce((acc, curr) => acc + curr, 0)
	const additionalTotal = quote_addons
		.map((addon) => Number(addon.total_cost))
		.reduce((acc, curr) => acc + curr, 0)
	const total = linesTotal + additionalTotal + linesHireTotal

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Heading />
				<Text style={styles.quoteText}>Quotation</Text>
				<ClientInfo quote={quote_data} />
				<Greetings />
				<Description description={quote_data.scope_of_work} />
				<View>
					<Text style={styles.heading}>Quote Lines</Text>
					<Table type={ColumnTypes.quote_lines} data={quote_lines} />
				</View>
				<View>
					<Text style={styles.heading}>Additional Items</Text>
					<Table type={ColumnTypes.quote_addons} data={quote_addons} />
				</View>

				<View style={{marginTop:20}}>
					<Table type={ColumnTypes.totals} data={total} />
				</View>

				<View>
					<Text style={styles.heading}>Additional Conditions</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						{quote_data.terms}
					</Text>
					{/* <Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						<Link src="https://www.toowoombascaffold.co.nz/wp-content/uploads/2021/05/Toowoomba-Scaffold-Terms-of-Trade-2021.pdf">
							View our Terms and Conditions here
						</Link>
					</Text> */}
				</View>
			</Page>
			{quote_data.files.length > 0 ? (
				<Page size="A4" style={styles.page}>
					<View style={styles.imageRow}>
						{quote_data?.files?.map(
							(
								image: {
									url: string
									description: string
									section_label: string
								},
								index: number
							) => (
								<View style={styles.image}>
									<Image
										key={index}
										src={image.url}
										style={{ maxWidth: '100%', maxHeight: '100%' }}
									/>
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 5,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Description:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.description}</Text>
									<View
										style={{
											flexDirection: 'row',
											gap: 5,
											alignItems: 'center',
											marginTop: 50,
										}}>
										<Text
											style={{
												...styles.subTextBold,
												fontSize: 10,
												margin: 0,
											}}>
											Section:
										</Text>
									</View>
									<Text style={styles.imageText}>{image.section_label}</Text>
								</View>
							)
						)}
					</View>
				</Page>
			) : null}
		</Document>
	)
}

import { IQuoteLine, IRates } from 'models/simple_quotes.model'

export const calculateLine = (
	rates: IRates[],
	line: IQuoteLine,
	field?: string
) => {
	if (
		(field === 'length' || field === 'height') &&
		!line.erect_and_dismantle_is_dirty &&
		!line.weekly_hire_fee_is_dirty
	) {
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) {
			return line
		}
		line.quantity = Number(line.length) * Number(line.height)
		const erectDismantleFee = Number(rate) * Number(line.quantity)
		const weekHireFee = Number(rate) * Number(line.hire_period)
		const total = Number(erectDismantleFee) + Number(weekHireFee)

		line.erect_and_dismantle = Number(erectDismantleFee.toFixed(2))
		line.weekly_hire_fee = Number(rate)
		line.total = Number(total.toFixed(2))
	} else {
		const erectDismantleFee = line.erect_and_dismantle
		const weekHireFee = Number(line.weekly_hire_fee) * Number(line.hire_period)
		const total = Number(erectDismantleFee) + Number(weekHireFee)
		line.total = Number(total.toFixed(2))
	}

	return line
}
